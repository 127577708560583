import React, { Fragment } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { withStyles } from "@material-ui/core";
import compose from "recompose/compose";

import {
  List,
  Datagrid,
  TextField,
  TopToolbar,
  Button,
  TextInput,
  DateTimeInput,
  Filter,
} from "react-admin";

import { CustomDateTimeField, ShowMultiMedia, } from "../../../components/helper";

import DownloadIcon from "@material-ui/icons/CloudDownload";

const styles = {
  drawerContent: {
    width: 300,
  },
};

const OperationButton = ({ record }) => {
  return (
    <TopToolbar style={{ justifyContent: "flex-start" }}>
      <Button
        label="ra.action.download"
        onClick={() => {
          const a = document.createElement('a');
          a.href = record.video_path; // 使用记录中的视频路径
          a.download = record.video_path.split('/').pop(); // 从路径中提取文件名
          a.target = '_blank'; // 在新标签页中打开
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }}
        >
          <DownloadIcon />
        </Button>
    </TopToolbar>
  );
};

const NoiseAlertListFilters = (props) => {
  return (
    <Filter {...props}>
      <TextInput label="ra.sources.mac" source="mac" alwaysOn />
      <DateTimeInput
        label="ra.sources.start_time"
        source="start_time"
        alwaysOn
      />
      <DateTimeInput
        label="ra.sources.end_time"
        source="end_time"
        alwaysOn
      />
    </Filter>
  );
};

class NoiseAlertList extends React.Component {
  render() {
    const { push, classes, ...props } = this.props;
    return (
      <Fragment>
        <List
          {...props}
          empty={false}
          bulkActionButtons={false}
          title="ra.menu.noise_alert"
          actions={false}
          filters={<NoiseAlertListFilters />}
        >
          <Datagrid>
            <TextField
              source="id"
              label="ra.sources.id"
              sortable={false}
            />
            <TextField
              source="mac"
              label="ra.sources.mac"
              sortable={false}
            />
            <TextField
              source="noise_db"
              label="ra.sources.noise_db"
              sortable={false}
            />
            <ShowMultiMedia
              source="video_path"
              label="ra.sources.video"
              sortable={false}
            />
            <CustomDateTimeField
              source="insert_time"
              label="ra.sources.insert_time"
              sortable={false}
            />
            <OperationButton />
          </Datagrid>
        </List>
      </Fragment>
    );
  }
  handleClose = () => {
    this.props.push("/air_noise/alert");
  };
}

export default compose(
  connect(undefined, { push }),
  withStyles(styles)
)(NoiseAlertList);
